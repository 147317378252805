import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

// Register necessary components
ChartJS.register(ArcElement, Tooltip, Legend);

const GaugeChart = ({ percentage = 53 }) => {
  const data = {
    labels: ["Progress", "Remaining"],
    datasets: [
      {
        data: [percentage, 100 - percentage], // Progress & Remaining
        backgroundColor: ["#FF7043", "#E0E0E0"], // Blue & Gray
        borderWidth: 0, // Remove borders
        circumference: 180, // Creates the semi-circle
        rotation: 270, // Rotates the chart to start from the bottom
        cutout: "75%", // Creates a hole in the middle
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false }, // Hide legend
      tooltip: { enabled: false }, // Disable tooltips
    },
  };

  return (
    <div
      style={{
        width: "200px",
        height: "100px",
        position: "relative",
        alignItems: "center",
        display: "flex",
        margin: "0 auto",
      }}
    >
      <Doughnut data={data} options={options} />
      {/* Centered Percentage */}
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          fontSize: "18px",
          fontWeight: "bold",
        }}
      >
        {percentage}%
      </div>
    </div>
  );
};

export default GaugeChart;
