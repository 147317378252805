import React, { useRef, useState, useEffect } from "react";
import { useDrop } from "react-dnd";
import { usePage } from "../../../layouts/PageContext";
import { useRowColumnContext } from "../../../layouts/RowColumnContext";
import Heading from "./Droppables/Heading";
import Image from "./Droppables/Image";
import Textarea from "./Droppables/Textarea";
import Button from "./Droppables/Button";
import Card from "./Droppables/Card";
const DroppableColumn = ({
  handleHide,
  rowId,
  columnIndex,
  setImageSize,
  handleRemoveColumn,
  rowIndex,
  item,
  setImageRadius,
  setMargin,
  setPadding,
  onDropItem,
  selectedElement,
  setSelectedElement,
}) => {
  const [{ isOver }, drop] = useDrop({
    accept: "ITEM",
    drop: (draggedItem) => onDropItem(draggedItem, rowId, columnIndex),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  // console.log(item.data)
  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState();
  const fileInputRef = useRef(null);
  const { formData, setFormData } = usePage();
  const { setRowColumnData } = useRowColumnContext();

  useEffect(() => {
    setRowColumnData({
      rowIndex: selectedElement.rowIndex,
      columnIndex: selectedElement.columnIndex,
    });
  }, [selectedElement.rowIndex, selectedElement.columnIndex, setRowColumnData]);

  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    // Clean up URL object
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const onSelectFile = (rowIndex, columnIndex, itemIndex, e) => {
    if (!e.target.files || e.target.files.length === 0) {
      console.error("No file selected");
      return;
    }

    const file = e.target.files[0];
    const updatedRows = [...formData.rows];
    const column = updatedRows[rowIndex].columns[columnIndex];

    let columnItems = column.data
      ? JSON.parse(column.data).items
      : column.items || [];
    const currentItem = columnItems[itemIndex];

    if (currentItem) {
      currentItem.preview = URL.createObjectURL(file);
      currentItem.file = file; // Assign the actual file object here
      currentItem.content = file.name;
      if (column.data) {
        column.data = JSON.stringify({ items: columnItems });
        column.items = columnItems;
      } else {
        column.items = columnItems;
      }
      setFormData({ ...formData, rows: updatedRows });
    } else {
      console.error(`Item at index ${itemIndex} does not exist in column.`);
    }
  };

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleClick = (
    rowIndex,
    columnIndex,
    itemIndex = null,
    part = null
  ) => {
    // console.log("Handle Click", rowIndex, columnIndex, itemIndex, part);
    if (
      rowIndex !== null &&
      columnIndex !== null &&
      formData.rows[rowIndex] &&
      formData.rows[rowIndex].columns[columnIndex]
    ) {
      const column = formData.rows[rowIndex].columns[columnIndex];
      let items;

      if (column.data) {
        const columnData = JSON.parse(column.data);
        items = columnData.items || [];
      } else {
        items = column.items || [];
      }
      const updatedSelectedElement = { rowIndex, columnIndex, itemIndex, part };
      setSelectedElement(updatedSelectedElement);
      if (itemIndex !== null && items[itemIndex]) {
        const item = items[itemIndex];
        setPadding({
          top: item.style?.padding?.top || 0,
          right: item.style?.padding?.right || 0,
          bottom: item.style?.padding?.bottom || 0,
          left: item.style?.padding?.left || 0,
        });

        setMargin({
          top: item.style?.margin?.top || 0,
          right: item.style?.margin?.right || 0,
          bottom: item.style?.margin?.bottom || 0,
          left: item.style?.margin?.left || 0,
        });

        setImageRadius(item.style?.borderRadius || 0);
        if (part === "card") {
          setImageRadius(column.cardStyle?.borderRadius || 0);
        }

        if (item.type === "image") {
          setImageSize({
            width: item.style?.size?.width || "50%",
            height: item.style?.size?.height || "auto",
          });
        } else {
          setImageSize({ width: 0, height: 0 });
        }
      } else {
        console.error("Item does not exist at the provided index.");
      }
    } else {
      console.error(
        "Selected element indices are not set correctly or do not exist in formData."
      );
    }
  };

  const handleFeatureChange = (
    rowIndex,
    columnIndex,
    itemIndex,
    e,
    part = null
  ) => {
    const { name, value } = e.target;
    const updatedRows = [...formData.rows];

    if (updatedRows[rowIndex] && updatedRows[rowIndex].columns[columnIndex]) {
      const column = updatedRows[rowIndex].columns[columnIndex];
      let currentItem;
      let isEditing = false;

      if (column.data) {
        const columnData = JSON.parse(column.data);
        currentItem = columnData.items[itemIndex];
        if (!currentItem) {
          console.error(
            `Item at index ${itemIndex} does not exist in column data.`
          );
          return;
        }
        isEditing = true;
      } else {
        currentItem = column.items[itemIndex];
        if (!currentItem) {
          console.error(`Item at index ${itemIndex} does not exist in column.`);
          return;
        }
      }
      if (!currentItem.style) {
        currentItem.style = {};
      }
      if (part === "heading") {
        currentItem.heading = value;
      } else if (part === "paragraph") {
        currentItem.paragraph = value;
      } else if (name === "content") {
        currentItem.content = value;
      } else {
        currentItem.style[name] = value;
      }
      if (isEditing) {
        const columnData = JSON.parse(column.data);
        columnData.items[itemIndex] = currentItem;
        column.data = JSON.stringify(columnData);
      }

      setFormData({ ...formData, rows: updatedRows });
    } else {
      console.error(
        "Row, column, or item does not exist at indices:",
        rowIndex,
        columnIndex,
        itemIndex
      );
    }
  };

  const isSelected =
    selectedElement.rowIndex === rowIndex &&
    selectedElement.columnIndex === columnIndex;
  // console.log('selected',isSelected)

  let items = [];

  if (item && item.data) {
    const parsedData = JSON.parse(item.data);
    items = parsedData.items || [];
  } else if (item && item.items) {
    items = item.items;
  }

  return (
    <div
      ref={drop}
      className={`flex-1 p-2 ${isOver ? "bg-blue-200" : "bg-none"} ${
        isSelected ? "selected-style" : ""
      } rounded h-auto relative`}
      style={{ border: "dashed", borderWidth: "1px", borderColor: "#cccaca" }}
    >
      <button
        className="bg-[#ff8662] m-1 absolute  right-5 px-2  rounded-full float-end cursor-pointer"
        onClick={() => handleRemoveColumn(rowId, columnIndex)}
        style={{ transform: "translate(-50%,-80%)" }}
      >
        <i className="fa-solid fa-xmark" style={{ color: "white" }}></i>
      </button>
      {items.length > 0 ? (
        items.map((subItem, index) => (
          <section
            key={index}
            onClick={() => handleClick(rowIndex, columnIndex, index)}
          >
            {subItem.type === "heading" && (
              <Heading
                subItem={subItem}
                handleFeatureChange={(e) =>
                  handleFeatureChange(rowIndex, columnIndex, index, e)
                }
              />
            )}
            {subItem.type === "image" && (
              <Image
                subItem={subItem}
                handleImageClick={handleImageClick}
                fileInputRef={fileInputRef}
                onSelectFile={(e) =>
                  onSelectFile(rowIndex, columnIndex, index, e)
                }
                padding={subItem.style.padding}
                margin={subItem.style.margin}
              />
            )}

            {subItem.type === "textarea" && (
              <Textarea
                handleFeatureChange={(e) =>
                  handleFeatureChange(rowIndex, columnIndex, index, e)
                }
                subItem={subItem}
              />
            )}
            {subItem.type === "button" && (
              <Button
                handleFeatureChange={(e) =>
                  handleFeatureChange(rowIndex, columnIndex, index, e)
                }
                subItem={subItem}
              />
            )}
            {subItem.type === "card" && (
              <Card
                handleFeatureChange={handleFeatureChange}
                onSelectFile={onSelectFile}
                fileInputRef={fileInputRef}
                subItem={subItem}
                padding={subItem.style.padding}
                margin={subItem.style.margin}
                rowIndex={rowIndex}
                columnIndex={columnIndex}
                itemIndex={index}
                handleImageClick={handleImageClick}
                handleClick={handleClick}
              />
            )}
          </section>
        ))
      ) : (
        <div
          className="flex items-center justify-center h-full text-text"
          onClick={handleHide}
        >
          Drop Here
        </div>
      )}
    </div>
  );
};

export default DroppableColumn;
