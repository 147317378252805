import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import axios from "axios";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import Helpers from "../../../Config/Helpers";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const RevenueChart = () => {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: "Site Traffic",
        data: [],
        backgroundColor: "#FF7043",
        borderRadius: 50,
        barThickness: 10,
        borderSkipped: false,
      },
    ],
  });

  useEffect(() => {
    const fetchTrafficData = async () => {
      try {
        const response = await axios.get(`${Helpers.apiUrl}traffic`); // Replace with actual API URL
        console.log("API Response:", response.data);
        const data = response.data;

        if (data) {
          // Sort data by date
          const sortedData = data.sort((a, b) => {
            const dateA = new Date(
              `${a.date.slice(0, 4)}-${a.date.slice(4, 6)}-${a.date.slice(6)}`
            );
            const dateB = new Date(
              `${b.date.slice(0, 4)}-${b.date.slice(4, 6)}-${b.date.slice(6)}`
            );
            return dateA - dateB;
          });

          // Extract dates for X-axis
          const dates = sortedData.map((item) => {
            return new Date(
              `${item.date.slice(0, 4)}-${item.date.slice(
                4,
                6
              )}-${item.date.slice(6)}`
            ).toLocaleDateString("en-US", { month: "short", day: "numeric" });
          });

          // Extract total users for Y-axis
          const totalUsers = sortedData.map((item) =>
            parseInt(item.totalUsers, 10)
          );

          console.log("Formatted Dates:", dates);
          console.log("Total Users:", totalUsers);

          // Update the state with API data
          setChartData({
            labels: dates,
            datasets: [
              {
                label: "Site Traffic",
                data: totalUsers,
                backgroundColor: "#FF7043",
                borderRadius: 50,
                barThickness: 10,
                borderSkipped: false,
              },
            ],
          });
        }
      } catch (error) {
        console.error("Error fetching traffic data:", error);
      }
    };

    fetchTrafficData();
  }, []);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          color: "#9E9E9E",
        },
        grid: {
          color: "rgba(0, 0, 0, 0.1)",
        },
      },
      x: {
        ticks: {
          color: "#9E9E9E",
        },
        grid: {
          display: false,
        },
      },
    },
  };

  return (
    <div
      style={{
        width: "90%",
        height: "300px",
        margin: "auto",
        padding: "1.5rem",
      }}
    >
      {/* <h4 className="text-dark mb-3 font-bold ml-4 mt-3 pt-3">
        Live Site Traffic
      </h4> */}
      <Bar data={chartData} options={options} />
    </div>
  );
};

export default RevenueChart;
