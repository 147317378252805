import React, { useEffect, useState } from "react";
import HelmetWrapper from "../../../Config/HelmetWrapper";
import Header from "./Header/Header";
import NewPageScreen from "./NewPageScreen";
import { useDrag } from "react-dnd";
import DraggableButton from "./Buttons/DraggableButton";
import DraggableImage from "./Buttons/DraggableImage";
import StyleSidebar from "./StyleSidebar";
import DragabbleSingleButton from "./Buttons/DragabbleSingleButton";
import DraggableTextArea from "./Buttons/DraggableTextArea";
import DragabbleCardButton from "./Buttons/DragabbleCardButton";
import Loader from "../../../layouts/Loader";
import { driver } from "driver.js";
import "driver.js/dist/driver.css";
import Helpers from "../../../Config/Helpers";
import axios from "axios";

function OptionSidebar({ type }) {
  const [loader, setLoader] = useState(true);
  const [container, setContainer] = useState(true);
  const [grid, setGrid] = useState(false);
  const [heading, setHeading] = useState(false);
  const [headingStyle, setHeadingStyle] = useState("");
  const [colorStyle, setColorStyle] = useState("white");
  const [bgColor, setbgColor] = useState("black");
  const [style, setStyle] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoader(false);
    }, 1500);
    return () => clearTimeout(timer);
  }, []);
  const [padding, setPadding] = useState({
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  });
  const [margin, setMargin] = useState({
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  });
  const [align, setAlign] = useState("center");
  const [imagesize, setImageSize] = useState({ width: 0, height: 0 });
  const [imageradius, setImageRadius] = useState({ radius: 0 });
  const initialSelectedElement = {
    rowIndex: null,
    columnIndex: null,
    itemIndex: null,
    part: null,
  };

  const [selectedElement, setSelectedElement] = useState(
    initialSelectedElement
  );
  // console.log('margin',margin)
  const handleSection = () => {
    setStyle(!style);
  };
  const handleHide = () => {
    setStyle(true);
  };
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "ITEM",
    item: { type },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));
  const handleClick = () => {
    setHeading(!heading);
  };
  const handleContainer = () => {
    setContainer(!container);
  };
  const handleGrid = () => {
    setGrid(!grid);
  };
  let driverObj; // Declare driverObj globally to reuse it across function calls
  let isListenerAdded = false;
  const startDriver = () => {
    // Initialize Driver.js only if it hasn't been initialized yet
    if (!driverObj) {
      driverObj = driver({
        showProgress: true,
        showButtons: true,
        opacity: 0.75,
        animate: true,
        padding: 10,
        steps: [
          {
            element: ".header-section",
            popover: {
              title: "Header Section",
              description: "Enter Page Name Here i.e, About",
              position: "bottom",
            },
          },
          {
            element: ".element-section",
            popover: {
              title: "Elements Section",
              description: "This section lets you manage elements.",
              position: "bottom",
            },
          },
          {
            element: ".style-section",
            popover: {
              title: "Style Section",
              description: "This section lets you customize styles.",
              position: "bottom",
            },
          },
          {
            element: ".layout-container",
            popover: {
              title: "Layout: Container",
              description: "Click here to manage container layout.",
              position: "right",
            },
          },
          {
            element: ".layout-grid",
            popover: {
              title: "Layout: Grid",
              description: "Click here to manage grid layout.",
              position: "right",
            },
          },
          {
            element: ".basic-grid",
            popover: {
              title: "Basic",
              description: "Darg and Drop Elements.",
              position: "right",
            },
          },
          {
            element: ".main",
            popover: {
              title: "Section",
              description: "Click Here to Add New Sections.",
              position: "right",
            },
          },
          {
            element: ".publishButton",
            popover: {
              title: "Publish Button",
              description: "Click here to Save the page.",
              position: "right",
            },
          },
        ],
        onReset: () => {
          // Remove the keydown listener when the tour ends
          if (isListenerAdded) {
            document.removeEventListener("keydown", handleKeydown);
            isListenerAdded = false; // Reset the listener flag
          }
        },
      });
    }

    // Function to handle the Enter key
    const handleKeydown = (e) => {
      if (e.key === "Enter") {
        e.preventDefault();
        driverObj.moveNext(); // Move to the next step
      }
    };

    // Add the keydown listener only once
    if (!isListenerAdded) {
      document.addEventListener("keydown", handleKeydown);
      isListenerAdded = true; // Mark the listener as added
    }

    // Start the tour
    driverObj.drive();
  };
  useEffect(() => {
    let user = Helpers.getItem("user", true);
    const tour = user.is_tour;
    console.log('user',user.is_tour);
    const id = user.id;
    if (tour === 0) {
      axios.post(`${Helpers.apiUrl}tour/update/${id}`);
      startDriver();
    }
  }, []);
  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <>
          {" "}
          <HelmetWrapper />
          <Header className="header-section" publishButton="publishButton" />
          <div className="flex flex-row bg-white">
            <div className="basis-[20%] p-5 bg-slate-50 h-[93vh] overflow-auto shadow-md">
              <div className="flex justify-around">
                <h3
                  className={`element-section mt-5 text-center font-bold mb-5 cursor-pointer bg-slate-100 p-5 rounded ${
                    style ? "border-2" : ""
                  }`}
                  onClick={handleSection}
                >
                  Elements
                </h3>
                <h3
                  className={`style-section mt-5 text-center font-bold mb-5 cursor-pointer bg-slate-100 p-5 rounded ${
                    style ? "" : "border-2"
                  }`}
                  onClick={handleSection}
                >
                  Style
                </h3>
              </div>
              <hr />
              {style ? (
                <>
                  <span className="text-md font-bold">
                    <i className="fa-sharp fa-solid fa-caret-down mr-3 mt-3"></i>{" "}
                    Layout
                  </span>
                  <div className="flex justify-around align-items-center mb-4">
                    <div className="layout-container mt-8 border-2 border-gray-300 w-[50%] text-center m-1 shadow-xs rounded">
                      <i
                        className="fa-sharp fa-light fa-container-storage pt-3"
                        style={{ fontSize: "1.5rem" }}
                      ></i>{" "}
                      <br />
                      <button className="btn" onClick={handleContainer}>
                        Container
                      </button>
                    </div>
                    <div className="layout-grid mt-8  border-2 border-gray-300 w-[50%] text-center m-1 shadow-xs rounded">
                      <i
                        className="fa-regular fa-grid pt-3 "
                        style={{ fontSize: "1.5rem" }}
                      ></i>{" "}
                      <br />
                      <button className="btn" onClick={handleGrid}>
                        Grid
                      </button>
                    </div>
                  </div>
                  <hr />
                  <span className="text-md font-bold">
                    <i className="fa-sharp fa-solid fa-caret-down mr-3 mt-5"></i>{" "}
                    Basic
                  </span>
                  <div className="basic-grid">
                    <div className="flex justify-around align-items-center">
                      <DraggableButton type="heading" />
                      <DraggableImage type="image" />
                    </div>
                    <div className="flex justify-around align-items-center">
                      <DraggableTextArea type="textarea" />
                      <DragabbleSingleButton type="button" />
                    </div>
                    <div className="flex justify-around align-items-center">
                      <DragabbleCardButton type="card" />
                    </div>
                  </div>
                  <button
                    className="bottom-0 h-16 bg-[#FF7A50] px-4 py-2 mt-5 w-full rounded"
                    onClick={startDriver}
                    style={{ color: "white", position: "sticky" }}
                  >
                    Show Me a Demo
                  </button>
                </>
              ) : (
                <StyleSidebar
                  onHeadingChange={setHeadingStyle}
                  setColorStyle={setColorStyle}
                  setPadding={setPadding}
                  padding={padding}
                  margin={margin}
                  setMargin={setMargin}
                  setAlign={setAlign}
                  setImageSize={setImageSize}
                  imagesize={imagesize}
                  setImageRadius={setImageRadius}
                  selectedElement={selectedElement}
                  rowIndex={0}
                  setbgColor={setbgColor}
                />
              )}
            </div>
            <NewPageScreen
              main="main"
              handleHide={handleHide}
              heading={heading}
              click={handleClick}
              container={container}
              handleContainer={handleContainer}
              headingStyle={headingStyle}
              colorStyle={colorStyle}
              padding={padding}
              margin={margin}
              align={align}
              imagesize={imagesize}
              imageradius={imageradius}
              selectedElement={selectedElement}
              setPadding={setPadding}
              setMargin={setMargin}
              setSelectedElement={setSelectedElement}
              bgColor={bgColor}
              setImageRadius={setImageRadius}
              handleGrid={handleGrid}
              grid={grid}
              setImageSize={setImageSize}
            />
          </div>
        </>
      )}
    </>
  );
}

export default OptionSidebar;
