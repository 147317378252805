import React from "react";
import Helpers from "../../../../Config/Helpers";

function Image({
  subItem,
  onSelectFile,
  fileInputRef,
  handleImageClick,
  padding,
  margin,
}) {
  const {
    top: topPadding,
    right: rightPadding,
    bottom: bottomPadding,
    left: leftPadding,
  } = padding;
  const {
    top: topMargin,
    right: rightMargin,
    bottom: bottomMargin,
    left: leftMargin,
  } = margin;
  return (
    <div>
      <>
        <input
          type="file"
          className="hidden"
          onChange={onSelectFile}
          ref={fileInputRef}
        />
        {subItem.preview || subItem.content ? (
          <div className="text-center">
            <div className="relative" style={{ display: "inline-block" }}>
              <img
                src={
                  subItem.preview
                    ? subItem.preview
                    : typeof subItem.content === "string"
                    ? `${Helpers.basePath}/storage/${subItem.content}`
                    : URL.createObjectURL(subItem.content)
                }
                className="cursor-pointer mx-auto"
                style={{
                  width: `${subItem.style.size?.width || "100"}%`,
                  height: `${subItem.style.size?.height || "auto"}`,
                  borderRadius: `${subItem.style.borderRadius || 0}px`,
                  padding: `${subItem.style.padding?.top || 0}px ${
                    subItem.style.padding?.right || 0
                  }px ${subItem.style.padding?.bottom || 0}px ${
                    subItem.style.padding?.left || 0
                  }px`,
                  margin: `${subItem.style.margin?.top || 0}px ${
                    subItem.style.margin?.right || 0
                  }px ${subItem.style.margin?.bottom || 0}px ${
                    subItem.style.margin?.left || 0
                  }px`,
                }}
                alt=""
              />

              <button
                className="bg-slate-200 m-1 absolute top-1 right-1 px-2 bg-opacity-75 rounded-full cursor-pointer"
                style={{ transform: "translate(50%, -50%)" }}
                onClick={handleImageClick}
              >
                <i className="fa fa-pencil" style={{ color: "black" }}></i>
              </button>
            </div>
          </div>
        ) : (
          <>
            <img
              src="/assets/image.png"
              className="w-[20%] text-center cursor-pointer mx-auto invert"
              onClick={handleImageClick}
              style={{
                padding: `${topPadding}px ${rightPadding}px ${bottomPadding}px ${leftPadding}px`,
                margin: `${topMargin}px ${rightMargin}px ${bottomMargin}px ${leftMargin}px`,
              }}
              alt=""
            />
          </>
        )}
      </>
    </div>
  );
}

export default Image;
