import React, { useEffect, useState } from "react";
import Sidebar from "../Components/Sidebar";
import Chart from "react-apexcharts";
import Header from "../Components/Header";
import axios from "axios";
import Helpers from "../../Config/Helpers";
import Loader from "../../layouts/Loader";
import RevenueChart from "./Charts/RevenueChart";
import GaugeChart from "./Charts/GaugeChart";

const Dashboard = () => {
  const [users, setUser] = useState("");
  const [newUser, setNewUser] = useState("");
  const [support, setSupport] = useState("");
  const [loader, setLoader] = useState(true);
  const [download, setDownload] = useState([]);

  const getData = async () => {
    const response = await axios.get(`${Helpers.apiUrl}user/info`);
    setUser(response.data.user);
    setNewUser(response.data.new_users_today);
    setSupport(response.data.support);
  };
  useEffect(() => {
    getData();
    const timer = setTimeout(() => {
      setLoader(false);
    }, 1000);
    return () => setTimeout(timer);
  }, []);
  // const [areaChartOptions, setAreaChartOptions] = useState({
  //   chart: {
  //     type: 'area',
  //     height: 350,
  //     toolbar: {
  //       show: false,
  //     },
  //   },
  //   stroke: {
  //     curve: 'smooth',
  //   },
  //   colors: ['#FF7A50'],
  //   markers: {
  //     size: 5,
  //     colors: ['#FF7A50'],
  //   },
  //   xaxis: {
  //     categories: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  //   },
  // });

  // const areaChartSeries = [
  //   {
  //     name: 'Site Traffic',
  //     data: [1500, 2300, 1800, 2500, 2200, 2900, 3200] // Example data for site traffic
  //   }
  // ];

  const [areaChartSeries, setAreaChartSeries] = useState([
    { name: "Site Traffic", data: [] },
  ]);
  const [areaChartOptions, setAreaChartOptions] = useState({
    chart: {
      type: "area",
      height: 350,
      toolbar: { show: false },
    },
    stroke: { curve: "smooth" },
    colors: ["#FF7A50"],
    markers: { size: 5, colors: ["#FF7A50"] },
    xaxis: { categories: [] },
  });

  useEffect(() => {
    const fetchTrafficData = async () => {
      try {
        const response = await axios.get(`${Helpers.apiUrl}traffic`);
        console.log("API Response:", response.data);
        const data = response.data;

        if (data) {
          // Sort data by date in ascending order
          const sortedData = data.sort((a, b) => {
            const dateA = new Date(
              `${a.date.slice(0, 4)}-${a.date.slice(4, 6)}-${a.date.slice(6)}`
            );
            const dateB = new Date(
              `${b.date.slice(0, 4)}-${b.date.slice(4, 6)}-${b.date.slice(6)}`
            );
            return dateA - dateB; // Ascending order
          });

          console.log("Sorted Data:", sortedData); // Add this to debug

          // Extract dates and totalUsers
          const dates = sortedData.map((item) => {
            const dateStr = item.date;
            const formattedDate = new Date(
              `${dateStr.slice(0, 4)}-${dateStr.slice(4, 6)}-${dateStr.slice(
                6
              )}`
            ).toLocaleDateString("en-US", {
              month: "short",
              day: "numeric",
              year: "numeric",
            });
            return formattedDate;
          });

          const totalUsers = sortedData.map((item) =>
            parseInt(item.totalUsers, 10)
          );

          console.log("Formatted Dates:", dates); // Add this to check dates
          console.log("Total Users:", totalUsers); // Debug total users

          // Update chart options and series
          setAreaChartOptions((prevOptions) => ({
            ...prevOptions,
            xaxis: { categories: dates },
          }));

          setAreaChartSeries([{ name: "Site Traffic", data: totalUsers }]);
        }
      } catch (error) {
        console.error("Error fetching traffic data:", error);
      }
    };

    fetchTrafficData();
  }, []);
  
  



  const [pieChartSeries, setPieChartSeries] = useState([0, 0]);
  const [pieChartOptions, setPieChartOptions] = useState({
    chart: {
      type: "pie",
    },
    labels: ["Active Users", "Inactive Users"],
    colors: ["#FF7A50", "#FFAA33"],
    legend: {
      position: "bottom",
    },
  });

  const fetchUserStats = async () => {
    try {
      const response = await axios.get(`${Helpers.apiUrl}userstats`);
      const data = response.data;

      if (data) {
        const { activeUsers, inactiveUsers } = data;
        setPieChartSeries([
          parseInt(activeUsers, 10),
          parseInt(inactiveUsers, 10),
        ]);
      }
    } catch (error) {
      console.error("Error fetching user stats:", error);
    }
  };

  const fetchDownloadStat = async () => {
    try {
      const response = await axios.get(`${Helpers.apiUrl}downloadstats`);
      setDownload(response.data.data);
    } catch (error) {
      console.error("Error fetching user stats:", error);
    }
  };
  const totalUsers = pieChartSeries.reduce((a, b) => a + b, 0);
  const activePercentage = totalUsers > 0 ? pieChartSeries[0] / totalUsers : 0;
  useEffect(() => {
    fetchDownloadStat();
    fetchUserStats();
  }, []);

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <>
          <Header />
          <div
            id="kt_app_wrapper"
            className="app-wrapper flex-column flex-row-fluid"
          >
            <Sidebar />
            <div className="container mx-auto p-6">
              <div className="grid grid-flow-col grid-rows-2 gap-4">
                <div className="row-span-2 ">
                  <div className="bg-white rounded-2xl shadow-sm p-4 m-3 flex items-center">
                    <div className="w-16 h-16 rounded-2xl overflow-hidden bg-[#fff3ef] flex items-center justify-center">
                      <i
                        className="fa-regular fa-user "
                        style={{ fontSize: "1.5rem", color: "#FF7A50" }}
                      ></i>
                    </div>
                    <div className="ml-4">
                      <p className="text-xl font-semibold">{users}</p>
                      <h3 className="text-gray-600">Registered User</h3>
                    </div>
                  </div>

                  <div className="bg-white rounded-2xl shadow-sm p-4 m-3 flex items-center">
                    <div className="w-16 h-16 rounded-2xl overflow-hidden bg-[#fff3ef] flex items-center justify-center">
                      <i
                        class="fa-regular fa-user-plus"
                        style={{ fontSize: "1.5rem", color: "#FF7A50" }}
                      ></i>
                    </div>
                    <div className="ml-4">
                      <h3 className="text-xl font-semibold">{newUser}</h3>
                      <p className="text-gray-600">New Users Today</p>
                    </div>
                  </div>
                </div>
                <div className="row-span-2">
                  <div className="bg-white rounded-2xl shadow-sm p-4 m-3 flex items-center">
                    <div className="w-16 h-16 rounded-2xl overflow-hidden bg-[#fff3ef] flex items-center justify-center">
                      <i
                        className="fa-solid fa-download"
                        style={{ fontSize: "1.5rem", color: "#FF7A50" }}
                      ></i>
                    </div>
                    <div className="ml-4">
                      <h3 className="text-xl font-semibold">{download}</h3>
                      <p className="text-gray-600">Total Downloads</p>
                    </div>
                  </div>

                  <div className="bg-white rounded-2xl shadow-sm p-4 m-3 flex items-center">
                    <div className="w-16 h-16 rounded-2xl overflow-hidden bg-[#fff3ef] flex items-center justify-center">
                      <i
                        className="fa-regular fa-message-smile"
                        style={{ fontSize: "1.5rem", color: "#FF7A50" }}
                      ></i>
                    </div>
                    <div className="ml-4">
                      <h3 className="text-xl font-semibold">{support}</h3>
                      <p className="text-gray-600">Support Request</p>
                    </div>
                  </div>
                </div>
                {/* </div> */}
                <div className="bg-[#ff8c66] rounded-2xl row-span-2 shadow-md">
                  {" "}
                  {/* 4/12 width */}
                  <h1
                    className=" text-3xl text-center mb-3 font-bold ml-4 mt-3 pt-3"
                    style={{ color: "white" }}
                  >
                    Congratulations
                  </h1>
                  <p
                    className="text-lg text-center mb-3 "
                    style={{ color: "white" }}
                  >
                    Total Sales
                  </p>
                  <h2
                    className="text-3xl text-center mb-3 font-bold "
                    style={{ color: "white" }}
                  >
                    $48K
                  </h2>
                  <button
                    style={{
                      color: "#ff8c66",
                      margin: "0 auto",
                      display: "flex",
                    }}
                    className="bg-white text-center px-10 py-3 font-bold rounded shadow "
                  >
                    View Sales
                  </button>
                </div>
              </div>
            </div>
            <div className="container mx-auto mt-3 px-5">
              <div className="grid grid-cols-1 lg:grid-cols-12 gap-4">
                <div className="card col-span-1 lg:col-span-8  shadow-sm">
                  {" "}
                  {/* 8/12 width */}
                  <h4 className="text-dark mb-3 font-bold ml-4 mt-3 pt-3">
                    Live Site Traffic
                  </h4>
                  {/* <Chart
                    options={areaChartOptions}
                    series={areaChartSeries}
                    type="area"
                    height={300}
                    className="px-5"
                  /> */}
                  <RevenueChart />
                </div>
                <div className="card col-span-1 lg:col-span-4  shadow-sm">
                  {" "}
                  <h4 className="text-dark mb-3 font-bold ml-4  mt-3 pt-3">
                    User Activity
                  </h4>
                  <div className="block w-full">
                    <Chart
                      options={pieChartOptions}
                      series={pieChartSeries}
                      type="pie"
                      height={300}
                    />
                  </div>
                  {/* <GaugeChart percentage={100} /> */}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Dashboard;
